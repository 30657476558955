<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="900px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="100px"
        :model="monitorDevice"
        :rules="ruleValidate"
        style="padding-right: 20px">
        <el-form-item :label="$t('monitorDevice.name')" prop="name">
          <el-input v-model="monitorDevice.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('monitorDevice.url')" prop="url">
          <el-input v-model="monitorDevice.url" :rows="4" type="textarea"></el-input>
        </el-form-item>
        <el-form-item :label="$t('monitorDevice.location')" prop="location">
          <el-input v-model="monitorDevice.location"></el-input>
        </el-form-item>
        <el-form-item :label="$t('monitorDevice.useUnit')" prop="useUnitId">
          <el-input v-model="monitorDevice.useUnitName" :placeholder="$t('common.pleaseSelect')" readonly>
            <el-button slot="append" icon="el-icon-search" @click="$refs.selectUseUnitProperty.open()"></el-button>
          </el-input>
          <use-unit-select ref="selectUseUnitProperty" @select="onSelectUseUnit"></use-unit-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import UseUnitSelect from "../company/useUnitCom/UseUnitSelect.vue";

  const moduleName = "monitorDevice";

  export default {
    components: {UseUnitSelect},
    data() {
      let ruleNumber = [
        {
          type: "number",
          required: true,
          min: 1,
          message: this.$t("common.tip.notNull"),
          trigger: "change",
        },
      ];
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        monitorDevice: {
          id: 0,
          name: "",
          url: "",
          location: "",
          useUnitName: "",
          useUnitId: 0,
        },
        ruleValidate: {
          name: [
            {required: true, message: this.$t("monitorDevice.name") + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
          url: [
            {required: true, message: this.$t("monitorDevice.url") + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
          location: [
            {
              required: true,
              message: this.$t("monitorDevice.location") + this.$t("common.tip.notNull"),
              trigger: "blur",
            },
          ],
          useUnitId: ruleNumber,
        },
      };
    },
    computed: {
      title() {
        return (this.monitorDevice.id === 0 ? this.$t("common.add") : this.$t("common.edit"));
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.monitorDevice.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.monitorDevice.id).then(res => {
          this.monitorDevice = res.data;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectUseUnit(row) {
        this.monitorDevice.useUnitId = row.id;
        this.monitorDevice.useUnitName = row.name;
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
        this.$refs.formValidate.resetFields();
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.monitorDevice).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.monitorDevice.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
