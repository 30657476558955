<template>
  <div style="display: flex;flex-direction: row">
    <el-main style="padding:0;width:100%">
      <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="monitorDevice">
        <template slot="adSearch">
          <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
        </template>
        <template slot="toolbar">
          <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
            {{ $l("elevatorNetApply.add", "+ 新增") }}
          </el-button>
        </template>
        <el-table-column prop="name" :label="$t('monitorDevice.name')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="useUnitName" :label="$t('monitorDevice.useUnit')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="url" :label="$t('monitorDevice.url')" width="100" align="center">
          <template slot-scope="scope">
            <el-link type="primary" @click="link(scope.row.url)">观看</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="location" :label="$t('monitorDevice.location')" width="140" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="editAuth" :label="$t('common.operation')" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
          </template>
        </el-table-column>
      </vm-table>
      <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
    </el-main>
  </div>
</template>
<script>
import EditDialog from "./monitorDeviceEdit.vue";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(807),
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.$refs.vmTable.getList(pageIndex);
    },
    link(url) {
      window.open(url);
    },
    handleSearch(event) {
      this.filter = event;
      this.getList(1);
    },
    handleAdSearch() {
      this.filter = "";
      this.adSearch = true;
      this.getList(1);
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("parts.no") + " " + row.no + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("monitorDevice", row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    changePage(pageIndex) {
      this.getList(pageIndex);
    },
    filterNode(value, data, node) {
      //console.log(data);
      if (!value) {
        return true;
      }
      if (node.parent.parent !== null && node.parent.visible) {
        return true;
      }
      return node.label.indexOf(value) !== -1;
    },
  },
};
</script>
<style lang="scss" scoped></style>
